import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Header.css';
import Skeleton from "react-loading-skeleton";
import SocialNetworks from 'components/SocialNetworks/SocialNetworks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faMobileRetro } from "@fortawesome/free-solid-svg-icons"

const Header = (headerSettings) => {

  
  let [isLoading, setIsLoading] = useState(true);
  let [enableHeader, setEnableHeader] = useState();
  let [contactEmail, setContactEmail] = useState(headerSettings.headerSettings.ContactEmail);
  let [contactMobilePhone, setContactMobilePhone] = useState();
  let [contactTelephone, setContactTelephone] = useState();
  let [desktopLogo, setDesktopLogo] = useState();
  let [desktopLogoHeight, setDesktopLogoHeight] = useState();
  let [desktopLogoWidth, setDesktopLogoWidth] = useState();
  let [enableSocialMediaHeader, setEnableSocialMediaHeader] = useState();
  let [mobileLogo, setMobileLogo] = useState();
  let [mobileLogoHeight, setMobileLogoHeight] = useState();
  let [mobileLogoWidth, setMobileLogoWidth] = useState();

  let [showAddressInformation, setShowAddressInformation] = useState();
  let [showTheSiteName, setShowTheSiteName] = useState();
  let [showTheSlogan, setShowTheSlogan] = useState();
  let [theSlogan, setSiteSlogan] = useState();
  let [siteName, setSiteName] = useState();
  
  let [headerColorClass, setHeaderColorClass] = useState('');
 
  
   
  useEffect(() => {
    setContactEmail(headerSettings.siteSettings.ContactEmail);
    setContactMobilePhone(headerSettings.siteSettings.ContactMobilePhone);
    setContactTelephone(headerSettings.siteSettings.ContactTelephone);
    setDesktopLogo(headerSettings.siteSettings.DesktopLogo);
    setEnableSocialMediaHeader(headerSettings.headerSettings.EnableSocialMediaHeader);
    setMobileLogo(headerSettings.siteSettings.MobileLogo);
    setShowAddressInformation(headerSettings.headerSettings.ShowAddressInformation);
    setShowTheSiteName(headerSettings.headerSettings.ShowTheSiteName);
    setShowTheSlogan(headerSettings.headerSettings.ShowTheSlogan);
    setSiteName(headerSettings.siteSettings.SiteName);
    setSiteSlogan(headerSettings.siteSettings.SiteSlogan);
    if (headerSettings.bodyClasses.includes("header-dark")) {
      setHeaderColorClass('bg-primary text-white');
    }

  }, [headerSettings]);

  useEffect(() => {
 
      let breadcrumbs = document.getElementsByTagName('body')[0].getAttribute('headers');
      if (breadcrumbs === "1") {
        setEnableHeader(true);
        return;
      }
      setEnableHeader(false);
    
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("sitesettings") === null) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1100);
    } else {
      setIsLoading(false);
    }

  }, []);

  if (!enableHeader){
    return;
  }
 
  return (

    <>
      {
        !isLoading ?
          <div style={{ minHeight: '117px' }} className={" header-wrapper " + headerColorClass}>
            <div className="container-fluid">

              {enableHeader &&
                <div className="tw-flex tw-justify-center">

                  <div className="header-logo-wrapper lg:tw-flex tw-items-center  tw-py-3">
                    {/* <script  src="https://tools.luckyorange.com/core/lo.js?site-id=1fa0f353"></script>
                    <a href="https://www.feefo.com/reviews/holiday-homes-in-italy"
                     target="_blank" 
                      className="header-feefo md:tw-left-[15%] lg:tw-left-[25%] xl:tw-left-[28%] md:tw-absolute" 
                     rel="noopener"><img alt="Feefo logo" border="0" src="https://api.feefo.com/api/logo?merchantidentifier=holiday-homes-in-italy&since=all" title="See what our customers say about us" /></a>
                     */}

                    <div className="header-logo lg:tw-inline">
                      
                      {desktopLogo  ?
                        <a href="/">
                          <img src={'https://www.holidayhomesinitaly.co.uk/clientImages/logo.png?width=150&height=80quality=100'} className="img-responsive tw-hidden md:tw-inline desktop-logo" />
                        </a>
                        : null}
                      {mobileLogo   ?
                        <a href="/">
                          <img src={mobileLogo} className="img-responsive md:tw-hidden mobile-logo  tw-m-auto tw-mt-3" />
                        </a>
                        : null
                      }  
                   </div>

                    {/* <div className='name-slogan'>
                      {showTheSiteName ? <div className="site-name">
                        {siteName}
                      </div> : null}
  
                      {showTheSlogan ? <div className="site-slogan">
                        { theSlogan }
                      </div> : null}
                    </div> */}

                  </div>

                  {/* <div className="header-info  xl:tw-flex sm:tw-justify-end tw-gap-3">
                    {showAddressInformation ?
                      <ul className="header-contact-info tw-p-0 tw-m-0 xl:tw-flex tw-gap-3 tw-text-center sm:tw-text-right">
                        {contactEmail && <li className="site-phone xl:tw-inline">
                          <a href={'tel:' + contactEmail.toString()} className="tw-no-underline">
                            <FontAwesomeIcon size="lg" icon={faEnvelope} color="primary" className="tw-mr-2" />
                            <span className="site-phone-text">{contactEmail}</span>
                          </a>
                        </li>}
                        {contactMobilePhone && <li className="site-phone-mob xl:tw-inline">
                          <a href={'tel:' + contactMobilePhone.toString()} className="tw-no-underline">
                            <FontAwesomeIcon size="lg" icon={faMobileRetro} color="primary" className="tw-mr-2" />
                            <span className="site-phone-mob-text">{contactMobilePhone}</span>
                          </a>
                        </li>}
                        {contactTelephone && <li className="site-phone xl:tw-inline">
                          <a href={'tel:' + contactTelephone.toString()} className="tw-no-underline">
                            <FontAwesomeIcon size="lg" icon={faPhone} color="primary" className="tw-mr-2" />
                            <span className="site-phone-text">{contactTelephone}</span>
                          </a>
                        </li>}

                      </ul>
                      : null}

                    {enableSocialMediaHeader && <SocialNetworks size={'xl'} wrapperClass="tw-flex tw-gap-3 tw-justify-center sm:tw-justify-end xl:tw-justify-start" className={"icons-social"} color={'primary'} socialNetworks={headerSettings.socialNetworks} />}

                  </div> */}

                </div>
              }
            </div>
          </div>
          :
          
          <div className={" tw-py-3 tw-min-h-[117px]"} style={{ minHeight: '117px'}}>
            <div className="tw-flex tw-justify-center" >
              <div className="header-logo-wrapper lg:tw-flex tw-items-center  tw-py-3">
                {/* <div className="header-feefo md:tw-left-[15%] lg:tw-left-[25%] xl:tw-left-[28%] md:tw-absolute">
                  <Skeleton height={51} width={200} />
                </div> */}
                <Skeleton height={93} width={150} />
              </div>
            </div >
          </div >
      }

    </>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
