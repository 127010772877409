import React, { useEffect, useState } from 'react';
import Skeleton from "react-loading-skeleton";
import { Outlet, Link } from "react-router-dom";
import { NavigationMenu } from 'components/NavigationMenu/NavigationMenu';
import useWindowSize from "utils/useWindowSize";
import styles from './MobileNavigation.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleBookmark, faPhone } from "@fortawesome/pro-light-svg-icons";

const MobileNavigation = (settings) => {
    const { width } = useWindowSize();
    const { height } = useWindowSize();

    let [isLoading, setIsLoading] = useState(true);

    let [enablenavigation, setEnablenavigation] = useState('');
    let [enableDropdown, setEnableDropdown] = useState();
    let [navigationDepth, setNavigationDepth] = useState();
    let [showHome, setShowHome] = useState();
    let [centerNav, setCenterNav] = useState();

    let [megaMenu, setMegaMenu] = useState();
    let [enableNavigationLogo, setEnableNavigationLogo] = useState();
    let [showWishlist, setShowWishlist] = useState();
    let [showWishlistUrl, setShowWishlistUrl] = useState();
    let [showWishlistName, setShowWishlistName] = useState();
    let [showguestLogin, setShowGuestLogin] = useState();
    let [guestLoginUrlName, setGuestLoginUrlName] = useState();
    let [guestLoginUrl, setGuestLoginUrl] = useState();

    let [showPhoneNumberNavigation, setShowPhoneNumberNavigation] = useState();

    let [menuNodes, setMenuNodes] = useState([]);

    let [showCurrencySelector, setShowCurrencySelector] = useState();
    let [showLanguageSwitcher, setShowLanguageSwitcher] = useState();
    let [showShowGoogleTranslate, setShowGoogleTranslate] = useState();
    let [showEnableSearch, setEnableSearch] = useState();

    let [callUsDictionary, setCallUsDictionary] = useState();

    let [currency, setCurrency] = useState();
    let [currecncies, setCurrencies] = useState();
    let [contactTelephone, setContactTelephone] = useState();
    let [desktopLogo, setDesktopLogo] = useState();
    let [desktopLogoHeight, setDesktopLogoHeight] = useState();
    let [desktopLogoWidth, setDesktopLogoWidth] = useState();
    let [mobileLogo, setMobileLogo] = useState();
    let [mobileLogoHeight, setMobileLogoHeight] = useState();
    let [mobileLogoWidth, setMobileLogoWidth] = useState();

    let [headerColorClass, setHeaderColorClass] = useState('');

    let [languages, setLanguages] = useState('');
    let [language, setLanguage] = useState('');
    let [navOpen, setNavOpen] = useState(false);

    useEffect(() => {

        if (settings.navigationSettings.CenterNavigationBar) {
            setCenterNav('center');
        } else {
            setCenterNav('end');
        }

        setContactTelephone(settings.siteSettings.ContactTelephone);
        setCallUsDictionary(settings.navigationSettings.CallUsDictionary);

        setEnableDropdown(settings.navigationSettings.EnableDropdowns);
        setMegaMenu(settings.navigationSettings.EnableMegaMenu);
        setEnableNavigationLogo(settings.navigationSettings.EnableNavigationLogo);
        setEnableSearch(settings.navigationSettings.Enablesearch);
        setShowGuestLogin(settings.navigationSettings.GuestLoginNavigation);
        setGuestLoginUrlName(settings.navigationSettings.GuestLoginUrlName);
        setGuestLoginUrl(settings.navigationSettings.GurestLoginUrl);
        setMenuNodes(settings.navigationSettings.MenuNodes);

        setNavigationDepth(settings.navigationSettings.NavigationDepth);
        setShowPhoneNumberNavigation(settings.navigationSettings.PhoneNumberNavigation);
        setShowCurrencySelector(settings.navigationSettings.ShowHideCurrencySelector);
        setShowHome(settings.navigationSettings.ShowHomeInTheNavigation);
        setShowLanguageSwitcher(settings.navigationSettings.LanguageSwitcher);
        setShowWishlist(settings.navigationSettings.WishlistNavigation);
        setShowWishlistUrl(settings.navigationSettings.WishlistUrl);
        setShowWishlistName(settings.navigationSettings.WishlistUrlName);

        setCurrencies(settings.currencies);

        setCurrency(settings.currency);

        setDesktopLogo(settings.siteSettings.DesktopLogo);
        setDesktopLogoHeight(settings.siteSettings.DesktopLogoHeight);
        setDesktopLogoWidth(settings.siteSettings.DesktopLogoWidth);
        setMobileLogo(settings.siteSettings.MobileLogo);
        setMobileLogoHeight(settings.siteSettings.MobileLogoHeight);
        setMobileLogoWidth(settings.siteSettings.MobileLogoWidth);

        setLanguages(settings.languages);
        setLanguage(settings.language);

        if (settings.bodyClasses.includes("navigation-dark")) {
            setHeaderColorClass('navigation-dark tw-bg-primary text-white');
        }

        if (settings.bodyClasses.includes("navigation-light") || settings.bodyClasses.includes("navigation-light") === undefined) {
            setHeaderColorClass('navigation-light text-black');
        }

        //console.log(settings);
    }, [settings]);

    useEffect(() => {

        let nav = document.getElementsByTagName('body')[0].getAttribute('nav');

        if (nav === "1") {
            setEnablenavigation(true);
            return;
        }
        setEnablenavigation(false);
    }, []);

    useEffect(() => {
        if (sessionStorage.getItem("sitesettings") === null) {
            setTimeout(() => {
                setIsLoading(false);
            }, 1600);
        } else {
            setIsLoading(false);
        }
    }, []);

    function expandNav() {
        setNavOpen(!navOpen);
    }

    useEffect(() => {
        if (navOpen) {
            document.body.classList.add('!tw-overflow-hidden');
        } else {
            document.body.classList.remove('!tw-overflow-hidden');
        }
    }, [navOpen]);


    const closeMenu = () => {
        setNavOpen(false);
    }



    if (!enablenavigation) {
        return;
    }
    return (
        <>

            {!isLoading ?
                <div className="tw-relative ">
                    <div className={`${headerColorClass} tw-h-full tw-bg-[#f7f7f7] Navigation sm:tw-p-3 tw-relative md:tw-place-items-center tw-z-30 lg:tw-grid tw-grid-cols-1 lg:tw-grid-cols-1 xl:tw-flex tw-justify-${centerNav}  xl:tw-items-center`}>
                        <div className='tw-flex tw-justify-between tw-p-1 tw-items-center tw-h-full'>
                            <a href="/">
                                <img src={mobileLogo} className="img-responsive mobile-logo" />
                            </a>
                            <div className='tw-flex tw-items-center tw-gap-3'>
                                {contactTelephone &&
                                    <a href={'tel:' + contactTelephone.toString()} className="tw-no-underline">
                                        <FontAwesomeIcon icon={faPhone} style={{ color: '#67b26f', fontSize: '27px' }} />
                                    </a>
                                }
                           
                                <a href='/my-favourites/' className="tw-no-underline md:tw-mt-0" target="_blank" rel="noopener">

                                        <span className="showWishlistName ">
                                            <FontAwesomeIcon icon={faCircleBookmark} style={{ color: '#67b26f', fontSize: '30px' }} />
                                        </span>
                                    </a>
                                
                                {width < 1024 ? (
                                    <div
                                        className={`tw-space-y-2 tw-p-2 tw-cursor-pointer ${navOpen ? styles.burgerButton : ''} ${navOpen ? 'open' : ''}`}
                                        onClick={expandNav}
                                    >
                                        <div className={`tw-w-8 tw-h-0.5 tw-bg-gray-600 tw-transition-transform tw-duration-300 ${navOpen ? 'tw-transform tw-rotate-45 tw-translate-y-2.5' : ''}`}></div>
                                        <div className={`tw-w-8 tw-h-0.5 tw-bg-gray-600 tw-transition-opacity tw-duration-300 ${navOpen ? 'tw-opacity-0' : ''}`}></div>
                                        <div className={`tw-w-8 tw-h-0.5 tw-bg-gray-600 tw-transition-transform tw-duration-300 ${navOpen ? 'tw-transform tw--rotate-45 tw--translate-y-2.5' : ''}`}></div>
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        {navOpen && <div className={`${styles.NavigationMenu} md:tw-flex lg:tw-justify-stretch xl:tw-items-stretch tw-gap-12`}> 

                            <NavigationMenu
                                    menuNodes={menuNodes}
                                    theme={settings.bodyClasses}
                                    open={navOpen}
                                    closeMenu={closeMenu}
                                    currecncies={currecncies}
                                />
                            </div>
                        }
                    </div>

                    <div className="top-banner tw-bg-primary">
                        <div className="tw-flex tw-justify-between tw-px-3 tw-py-1">
                            <div className="tw-flex tw-justify-center tw-items-center"><img src="/clientImages/banner-icon.svg" width="40" /><span className="banner-text tw-text-white tw-ml-3 ">Italy Villa Specialist</span></div>
                            <div className="tw-justify-center tw-items-center  tw-hidden md:tw-flex"><img src="/clientImages/banner-icon.svg" width="40" /><span className="banner-text tw-text-white tw-ml-3 " >Italy Villa Specialist</span></div>
                            <div className="tw-justify-center tw-items-center  tw-hidden md:tw-flex"><img src="/clientImages/banner-icon.svg" width="40" /><span className="banner-text tw-text-white tw-ml-3 " >Italy Villa Specialist</span></div>
                            <div className="tw-justify-center tw-items-center  tw-hidden md:tw-flex"><img src="/clientImages/banner-icon.svg" width="40" /><span className="banner-text tw-text-white tw-ml-3 " >Italy Villa Specialist</span></div>
                        </div>
                    </div>
                </div> :

                <div className={"tw-py-3 tw-bg-primary"}>
                    <div className="container-fluid " >
                        <div className={`${headerColorClass} Navigation tw-p-3 tw-relative tw-z-30  lg:tw-grid lg:tw-grid-cols-2 xl:tw-flex tw-justify-${centerNav}  xl:tw-items-center`}>
                            {enableNavigationLogo && <div className="navigation-logo lg:tw-inline tw-flex tw-justify-between tw-items-center lg:tw-row-span-3 tw-grid-rows-2">
                                {desktopLogo !== null ?
                                    <Skeleton height={30} width={100} />
                                    : null}
                                {mobileLogo !== null ?
                                    <a href="/">
                                        <Skeleton height={30} width={300} />
                                    </a>
                                    : null
                                }


                            </div>
                            }
                            {
                                <div className="tw-hidden lg:tw-grid 2xl:tw-flex lg:tw-justify-stretch lg:tw-items-stretch tw-gap-12">
                                    <div className="tw-block tw-p-0 tw-m-0 lg:tw-flex lg:tw-justify-between tw-gap-12">
                                        <Skeleton height={20} width={80} />
                                        <Skeleton height={20} width={80} />
                                        <Skeleton height={20} width={80} />
                                        <Skeleton height={20} width={80} />
                                        <Skeleton height={20} width={80} />
                                        <Skeleton height={20} width={80} />
                                    </div>

                                </div>
                            }
                        </div>
                    </div >
                </div >
            }


            <Outlet />
        </>
    );
};

export default MobileNavigation;
