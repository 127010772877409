import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './PropertyRating.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
 
const PropertyRating = ({ ratingNumber }) => {
   
  return(
      <>
       {parseInt(ratingNumber) > 0 ?
        <div className="tw-static " >
          <div className={styles.PropertyRating + ' tw-absolute tw-top-2 tw-left-3 tw-bg-white tw-p-2 tw-rounded-full'}>
            <div>
              <span className='tw-font-bold tw-rounded-full'>{ratingNumber}</span>
              <FontAwesomeIcon icon={fasStar} color="#ffa900" className="tw-right-5 tw-top-5  " />
            </div>
          </div>
        </div> :
        null}
      </>
  )
};

PropertyRating.propTypes = {};

PropertyRating.defaultProps = {};

export default PropertyRating;
