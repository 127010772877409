import React, { useEffect, useState, useContext } from 'react';
import SocialNetworks from 'components/SocialNetworks/SocialNetworks';
import styles from './Footer.module.css';
import { SiteSettingsContext } from '../../contexts/SiteSettingsProvider';
import JsContent from 'components/Helpers/JsContent';
import MailchimpForm from 'components/Helpers/MailchimpForm'; // Adjust the import path as necessary

const Footer = () => {



  const settings = useContext(SiteSettingsContext);

 
  const hardcodedHTML = `
  <script src="https://apps.elfsight.com/p/platform.js" defer></script>
  <div class="elfsight-app-c138ba89-ac50-4ce1-992d-5fb725d9c620"></div>
`;
  const [ContactByTelephoneText, setContactByTelephoneText] = useState();
  const [ContactTelephone, setContactTelephone] = useState();
  const [ContactBySocialNetworksText, setContactBySocialNetworksText] = useState();

  const [col1Title, setCol1Title] = useState();
  const [col2Title, setCol2Title] = useState();

 

  useEffect(() => {

    if (settings) {
      setContactByTelephoneText(settings.FooterTexts?.ContactByTelephoneText);
      setContactTelephone(settings.FooterTexts?.ContactTelephone);
      setContactBySocialNetworksText(settings.FooterTexts?.ContactBySocialNetworksText);

      setCol1Title(settings.FooterTexts?.FooterColumn1Title);
      setCol2Title(settings.FooterTexts?.FooterColumn2Title);


    }




  }, [settings]);

  let nav1Items;
  let nav2Items;

  if (!settings){
    return null;
  }
  
  if (settings) {
    let nav1 = settings.BotNav1;
    let nav2 = settings.BotNav2;
    let nav3 = settings.FooterColumn4Text;
    if (nav1){
      nav1Items = nav1.map((item, index) =>
        <li key={index} >
          <a href={item.Url}>
            {item.Name}
          </a>
        </li>
      );
    }

    if (nav2){
      nav2Items = nav2.map((item, index) =>
        <li key={index} >
          <a href={item.Url}>
            {item.Name}
          </a>
        </li>
      );
    }


  }

  if (settings){
 
    return (
      <>
        <MailchimpForm />
        <div className={styles.Footer + " tw-py-5 tw-bg-primary_dark tw-text-white"}>



          <div className="top-footer tw-border-b tw-border-zinc-600 tw-py-3">
            <div className="container">
              <div className="tw-grid md:tw-grid-cols-2 tw-mb-6 tw-grid-cols-auto">
                <div className="tw-text-2xl">
                  {ContactByTelephoneText}
                  {ContactTelephone}
                </div>
                <div className="tw-flex md:tw-justify-end tw-text-2xl tw-mt-4 md:tw-mt-0">
                  <span className="tw-mr-3">{ContactBySocialNetworksText}</span> {settings && <SocialNetworks socialNetworks={settings.SocialNetworks} wrapperclassName="tw-flex tw-gap-3 tw-justify-center md:tw-justify-end" />}
                </div>
              </div>
            </div>
          </div>
          <div className="mid-footer tw-border-b tw-border-zinc-600 tw-py-8">
            <div className="container">
              <div className="tw-grid md:tw-grid-cols-4">
                <div className="">
                  <h2 className="tw-text-white">{col1Title}</h2>
                  <ul className="tw-p-0 tw-m-0">
                    {nav1Items}
                  </ul>
                  <div className="title footer-images tw-mt-5">
                    <div className="footer-logos-in">
                      <a href="https://abtoi.com/" target="_blank" rel="noreferrer">
                        <img src="/clientImages/abtoi_new-trans.png" width="200" alt="Abtoi logo" className="tw-bg-white tw-object-contain img-responsive abtoi" />
                      </a>
                    </div>


                  </div>
                </div>
                <div className="">
                  <h2 className="tw-text-white">{col2Title}</h2>
                  <ul className="tw-p-0 tw-m-0">
                    {nav2Items}
                  </ul>
                  <div className="creative_prf tw-max-w-[200px] tw-mt-5">
                    <script type="text/javascript" src="https://creative.prf.hn/creative/camref:1101lmbMk/creativeref:1100l46514"></script><a href="https://prf.hn/click/camref:1101lmbMk/creativeref:1100l46514/screativeref:1100l88665" target="_blank"><img src="https://creative.prf.hn/source/screativeref:1100l88665" width="300" height="250" border="0" /></a>
                  </div>
                </div>
                <div className="">
                  <h2 className="tw-text-white">Сollections</h2>  
                  {settings && <span dangerouslySetInnerHTML={{ __html: settings.FooterTexts?.FooterColumn4Text }}></span>}

                </div>
                <div className="">
                  <JsContent content={hardcodedHTML} />
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-footer tw-text-center tw-pt-8 tw-pb-3">
            <div className="container">
              {settings && <span dangerouslySetInnerHTML={{ __html: settings.FooterTexts?.FooterBottom1Text }}></span>}
              {settings && <div>
                <span>Copyright © {new Date().getFullYear()}</span> <span dangerouslySetInnerHTML={{ __html: settings.FooterTexts?.FooterBottom2Text }}></span>
              </div>}
              <div className="pay-logos tw-flex tw-justify-center tw-mt-3">
                <img src="/media/36043979/logo-opayo.png" width='150' alt="Secure online payments powered by WorldPay" className="img-responsive logo-opayo tw-object-contain tw-mr-5" />
                <img src="/clientImages/pay.png" width='150' alt="Payment Method" className="img-responsive logo-pay tw-object-contain" />
              </div>
            </div>
          </div>

        </div>

      </>
    );
  }


};



export default Footer;