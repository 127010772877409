import React, { useState } from "react";

function Slider({ url,title, subtitle, children}) {
 
 
    return (
        <div 
            style={{ backgroundImage: `url(https://www.holidayhomesinitaly.co.uk/${url})` }}
            className="tw-flex tw-flex-col tw-justify-center lg:tw-items-center tw-w-full tw-min-h-[50vh] tw-bg-no-repeat tw-bg-cover tw-bg-center"
        >
            <div className="tw-max-w-[85%] tw-text-center tw-mx-auto">
                <h1 className="tw-text-[#fff] text-shadow">{title}</h1>
                <h4 className="tw-text-[#fff] text-shadow">{subtitle}</h4>
            </div>
            {children}
        </div>
    );   
}

export default Slider;
 


