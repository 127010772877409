import { useEffect, useState, useContext } from 'react';
import { SEOContext, CurrencyContext } from '../../contexts/SiteSettingsProvider';
import WidgetsList from 'components/WidgetsList/WidgetsList';
import SkeletonFolder from './SkeletonFolder/SkeletonFolder';

const FolderPage = ({ pageId, keyID }) => {
    const { updateSeo } = useContext(SEOContext);
    const [currency, setCurrency] = useContext(CurrencyContext);
    const [widgets, setWidgets] = useState([]);
    const [response, setResponse] = useState(null);
    const [title, setTitle] = useState(null);
    const [content, setContent] = useState(null);
    const [settings, setSettings] = useState(null);
    const [subContentPages, setSubContentPages] = useState([]);   

    const getContent = (sortDirection, Sort, Show, Page, clear) => {

        if (pageId) {
            fetch(`${process.env.REACT_APP_BASE_URL}apis/pagefolderpage?pageid=${pageId}&Currency=${currency}`)
                .then(response => response.json())
                .then(data => {
 
                    setResponse(true);
                    setWidgets(data.Page.Widgets);
                    setTitle(data.Page.PageH1Title);
                    setContent(data.Page.PageContent);
                    setSettings(data.Settings.SearchFormSettings);
                    setSubContentPages(data.Page.SubContentPages);
                    updateSeo(data.Settings.Seo);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }

    useEffect(() => {
        getContent();
    }, [pageId, currency])

    if (!response){
        return <SkeletonFolder/>
    }

    return (
        <>
     

            
                <div>
                <div className="tw-container tw-m-auto tw-p-3">
                        {title && <h1>{title}</h1>}

                        {content && <div dangerouslySetInnerHTML={{ __html: content }}></div>}

                   
                        {subContentPages &&
                            <div className="tw-mt-3 tw-grid sm:tw-grid-cols-1 md:tw-grid-cols-2 2xl:tw-grid-cols-3 tw-gap-4 tw-flex tw-items-stretch ">
                                {subContentPages.map((page, index) => (
                                    <div className="tw-flex tw-flex-col tw-relative">
 
                                        <a href={page.Url} className="tw-no-underline  " key={index}>
                                            <div className="image-container tw-flex-grow tw-relative">
                                                {page.Image ?
                                                    <img src={page.Image.Url} alt={page.Name} className="image" /> :
                                                    <div className="tw-h-full tw-w-full tw-min-h-[200px] tw-bg-primary"></div>
                                                }
           

                                            </div>

                                        </a>
                                        {page.Title &&
                                            <h4 className="tw-shadow-md tw-px-2 tw-py-1 tw-text-center">
                                                {page.Title}
                                            </h4>
                                        }
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div> 
            

            
            <WidgetsList Widgets={widgets} pageId={pageId} keyID={keyID} settings={settings} />
        </>
    );
};



export default FolderPage;
