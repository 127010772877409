import React, { useEffect } from 'react';

const MailchimpForm = () => {

    useEffect(() => {
        // Add the CSS stylesheet dynamically
        const link = document.createElement('link');
        link.href = "//cdn-images.mailchimp.com/embedcode/classic-10_7.css";
        link.rel = 'stylesheet';
        link.type = 'text/css';
        document.head.appendChild(link);

        // Add the Mailchimp validation script dynamically
        const script = document.createElement('script');
        script.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
        script.type = 'text/javascript';
        document.body.appendChild(script);

        // Cleanup function: remove the scripts and styles when the component unmounts
        return () => {
            document.head.removeChild(link);
            document.body.removeChild(script);
        }
    }, []); // Empty dependency array means this useEffect runs once when the component mounts

    return (
        <div id="mail_subsc">
            <style type="text/css">
                {`
          div#mail_subsc {
            background: #eee;
          }
          div#mc_embed_signup {
            background: #eee;
            margin: auto;
          }
        `}
            </style>

            <div id="mc_embed_signup">
                
                <form action="https://ashbydigital.us4.list-manage.com/subscribe/post?u=20b2243fb0d073d1573945486&amp;id=413eb8afe6" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                    <div id="mc_embed_signup_scroll" className="tw-max-w-[400px] tw-m-auto">
                        <h2>Subscribe</h2>
                        <div className="mc-field-group">
                            <label htmlFor="mce-EMAIL">Email Address  <span className="asterisk">*</span></label>
                            <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" />
                        </div>
                        <div id="mce-responses" className="clear">
                            <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                            <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                        </div>
                        <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                            <input type="text" name="b_20b2243fb0d073d1573945486_413eb8afe6" tabIndex="-1" value="" />
                        </div>
                        <div className="clear">
                            <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default MailchimpForm;