import React, { useRef, useState, useContext, useEffect } from 'react';
import styles from './CurrencySelector.module.css';
import Form from 'react-bootstrap/Form';
import { useLocation } from "react-router-dom";
import { useReadCookie } from 'utils/useReadCookie';
import useGetUrlParams from 'utils/useGetUrlParams';
import { SiteSettingsContext, CurrencyContext } from '../../contexts/SiteSettingsProvider';

function writeCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

let firstLoad = true;

const CurrencySelector = (currencies) => {
  const [currency, setCurrency] = useContext(CurrencyContext);
  const currencySelector = useRef();
  const [currencyArr, setCurrencyArr] = useState([]);
  const [currencyParam] = useGetUrlParams('Currency');
  const [currentCurrency] = useReadCookie('new_currency_6916');
  let location = useLocation();



  useEffect(() => {
    let currecnciesList = currencies.currecncies;
    let arr = [];
    for (const prop in currecnciesList) {
      arr.push(`${prop}`);
    }
    setCurrencyArr(arr);

    if (arr.length > 0 && firstLoad) {

      setTimeout(function () {
        firstLoad = false;
        if (currencyParam && currencyParam !== 'undefined') {
          writeCookie('new_currency_6916', currencyParam, 365);
          currencySelector.current.value = currencyParam;
          setCurrency(currencyParam);
          return;
        }

        if (currentCurrency !== "new_currency_6916" && currentCurrency !== "undefined" && currentCurrency !== '') {
          currencySelector.current.value = currentCurrency;
          setCurrency(currentCurrency);
          return;
        }


        if (currentCurrency === "new_currency_6916" && currencyParam === '') {
     
          //set up default currency
          writeCookie('new_currency_6916', arr[currentCurrency], 365);
          writeCookie('old_currency_6916', arr[currentCurrency], 365);
          //currencySelector.current.value = arr[currentCurrency];
          setCurrency(arr[currentCurrency]);
          return;
        }

       
        
        if (!currency || currency==='') {
         
          setCurrency(arr[0]);
          currencySelector.current.value = arr[0];
        }

      }, 1);
    }
  }, [currencyParam, location]);

  let options = currencyArr.map((element, index) => {
    return <option value={element} key={index}>{element}</option>;
  });

  const changeCurrency = (e) => {
    writeCookie('old_currency_6916', currency, 365);
    writeCookie('new_currency_6916', e.target.value, 365);
    setCurrency(e.target.value);
    // currencySelector.current.value = e.target.value;
  }

  return (
    <div className={styles.CurrencySelector + " tw-mb-2 tw-mt-2 lg:tw-m-0 tw-pl-3 md:tw-pl-0"}>
      <div className={styles.CurrencyConverter + " tw-flex tw-items-center "} >
        <Form.Select onChange={changeCurrency} ref={currencySelector}>
          {options}
        </Form.Select >
      </div>
    </div>
  );
};

export { CurrencySelector };
