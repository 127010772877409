import React from 'react';

const WidgetWrapper = ({ data, children }) => {

    const outerBackgroundStyle = data.UseFullScreenWidthForWidgetcContent
        ? { backgroundColor: data.BackgroundColor, backgroundImage: `url(${data.BackgroundImage})` }
        : {};

    const innerBackgroundStyle = (data.UseFullScreenWidthForWidgetContent && data.UseFullScreenWidthForWidgetcContent)
        ? {}
        : { backgroundColor: data.BackgroundColor, backgroundImage: `url(${data.BackgroundImage})` };

    // Apply 'tw-p-3' class if BackgroundColor or BackgroundImage exists
    const paddingClass = (data.BackgroundColor || data.BackgroundImage) ? 'tw-p-5' : '';

    return (
        <div style={outerBackgroundStyle} className='tw-my-5 tw-p-3 widgets-Wrapper'>
            <div style={innerBackgroundStyle}
                className={`${data.UseFullScreenWidthForThisWidget ? '' : 'tw-container tw-m-auto '} ${paddingClass}`}>
                {children}
            </div>
        </div>
    );
};

export default WidgetWrapper;