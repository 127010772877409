import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faUserFriends, faBath } from '@fortawesome/free-solid-svg-icons';

const getAttributeValue = (categories, name) => {
    if (!categories) return '';
    const attribute = categories.find(category => category.Alias === name);
    if (!attribute) return '';
    return attribute.Options.find(option => attribute.SelectedOptions.includes(option.Id))?.Name || '';
};

const MainAttrs = ({ propertyAttributes }) => {
    const sleeps = getAttributeValue(propertyAttributes?.Categories, 'Sleeps');
    const bedrooms = getAttributeValue(propertyAttributes?.Categories, 'Bedrooms');
    const bathrooms = getAttributeValue(propertyAttributes?.Categories, 'Bathrooms');
    return (
        <div className="main-attrs tw-inline-flex tw-gap-5 tw-text-xl tw-p-5 tw-bg-slate-100 tw-my-3">

            {sleeps && (
                <div className="tw-flex tw-items-center tw-space-x-2">
                    <FontAwesomeIcon icon={faUserFriends} />
                    <span>{sleeps} <span className='tw-hidden sm:tw-block'>Sleeps</span></span>
                </div>
            )}
            
            {bedrooms && (
                <div className="tw-flex tw-items-center tw-space-x-2">
                    <FontAwesomeIcon icon={faBed} />
                    <span >{bedrooms} <span className='tw-hidden sm:tw-block'>Bedrooms</span></span>
                </div>
            )}
            {bathrooms && (
                <div className="tw-flex tw-items-center tw-space-x-2">
                    <FontAwesomeIcon icon={faBath} />
                    <span >{bathrooms} <span className='tw-hidden sm:tw-block'>Bathrooms</span></span>
                </div>
            )}
        </div>
    );
};

export default MainAttrs;