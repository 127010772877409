import React, { useState, useEffect, useRef } from 'react';
import './Guests.css';
import DropDownPanel from '../../DropDownPanel/DropDownPanel';
import GuestsCounter from './GuestsCounter/GuestsCounter';

const GuestsMobile = ({ onToggle, isOpen, changeAdults, changeChildren, changeInfants, enableGuestFilter, clearField, settings, changedNumberOfAdults, changedNumberOfChildren, changedNumberOfInfants, changedLocation }) => {
    const [adults, setAdults] = useState(changedNumberOfAdults || 1);
    const [children, setChildren] = useState( changedNumberOfChildren || 0);
    const [infants, setInfants] = useState(changedNumberOfInfants || 0);
    const [total, setTotal] = useState( adults + children + infants);

    const [maxGuest] = useState(20);


    function adultsHasChanged(val) {
        setAdults(val);
        changeAdults(val);
    }

    function childrenHasChanged(val) {
        setChildren(val);
        changeChildren(val);
    }

    function infantsHasChanged(val) {
        setInfants(val);
        changeInfants(val);
    }

    useEffect(() => {
        setTotal(parseInt(adults) + parseInt(children) + parseInt(infants));
    }, [adults, children, infants])

    useEffect(() => {

        if (clearField > 0) {

            setAdults(1);
            setChildren(0);
            setInfants(0);
            setTotal(1);
            changeAdults();
            changeChildren();
            changeInfants();
        }
    }, [clearField])

    const hasUpdatedOnce = useRef(false);
 
    useEffect(() => {
        if (!hasUpdatedOnce.current && !enableGuestFilter) {
            changeAdults(null);
            changeChildren(null);
            changeInfants(null);
            hasUpdatedOnce.current = true;
        }
    }, [enableGuestFilter, changeAdults, changeChildren, changeInfants]);


    return (
        <>
            {
                enableGuestFilter &&
                <div className={!isOpen ? "" : "tw-border tw-p-5"}>


                    <h5 onClick={() => onToggle()} className={` ${isOpen ? "!tw-m-0" : "tw-border tw-p-5 "}`} >
                        Who
                        {total > 0 && <span className="tw-text-gray-500 tw-text-sm tw-ml-2">{total + " " + (total === 1 ? 'Guest' : 'Guests')}</span>}
                    </h5>

                        <div
                            title={total + " " + (total === 1 ? 'Guest' : 'Guests')}
                            className={isOpen ? "tw-block" : "tw-hidden"}
                        >
                            <GuestsCounter clearField={clearField} name="Adults" min='1' max={maxGuest} initial={adults} upCount={adultsHasChanged} settings={settings} />
                            <GuestsCounter clearField={clearField} name="children" min='0' max={maxGuest} initial={children} upCount={childrenHasChanged} settings={settings} />
                            <GuestsCounter clearField={clearField} name="Infants" min='0' max={maxGuest} initial={infants} upCount={infantsHasChanged} settings={settings} />
                        </div>
                </div>
            }
        </>
    );
};



export default GuestsMobile;
