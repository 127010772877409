import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './OverlayContainer.css';

function createOverlayElement() {
  const el = document.createElement('div');
  el.style.position = 'absolute';
  el.style.display = 'inline-block';
  el.style.width = '250px';
  el.style.zIndex = '9';
  el.setAttribute('class', 'OverlayContainer');

  el.addEventListener("click", function(e) {
    for (const element of document.querySelectorAll(".OverlayContainer")) {
      element.style.zIndex = 9
    }
    this.style.zIndex = '999';
    
  });
  return el;
}

 
const OverlayContainer = (props) => {
 
  const overlay = useRef(null);
  const el =  useRef(null);

  class OverlayView extends window.google.maps.OverlayView {
    position = window.google.maps.LatLng | null;
    content = null;

    constructor(props) {
      super();
      props.position && (this.position = props.position);
      props.content && (this.content = props.content);
    }

    onAdd = () => {
      if (this.content) this.getPanes().floatPane.appendChild(this.content);
      
    };  

    onRemove = () => {
      if (this.content?.parentElement) {
        
        this.content.parentElement.removeChild(this.content);
      }
    };

    draw = () => {
      if (this.position) {
        const divPosition = this.getProjection().fromLatLngToDivPixel(
          this.position
        );
        //setMarkerPosition(divPosition);
        this.content.style.left = divPosition.x + 'px';
        this.content.style.top = divPosition.y + 'px';
      }
    };
  }

 

  if (props.map) {
 
    el.current = el.current || createOverlayElement()
    overlay.current = overlay.current || new OverlayView(
      {
        position: new window.google.maps.LatLng(props.position.lat, props.position.lng),
        content: el.current
      }
    )
    overlay.current.setMap(props.map)
    return ReactDOM.createPortal(props.children, el.current);
  }
 

 // return null;
};

OverlayContainer.propTypes = {};

OverlayContainer.defaultProps = {};

export default OverlayContainer;
