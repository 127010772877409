import React, { useState, useEffect, useRef, createRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import useWindowSize from "utils/useWindowSize";
import { useLocation } from 'react-router-dom';
import { PropertyInputSearch } from 'components/PropertyInputSearch/PropertyInputSearch';
import { LanguageSwitcher } from 'components/LanguageSwitcher/LanguageSwitcher';
import { CurrencySelector } from 'components/CurrencySelector/CurrencySelector';
import { GoogleTranslateSwitcher } from 'components/GoogleTranslateSwitcher/GoogleTranslateSwitcher';

export const NavigationMenu = ({ menuNodes, open, closeMenu, currecncies }) => {
  const itemEls = useRef([]);
  const DropMenuRefs = useRef([]);
  const location = useLocation();
  
  const { width } = useWindowSize();
 
 
  const [showDropdown, setShowDropdown] = useState(true);
 
  function runFunction(ref) {
    let dropDown = ref.nextElementSibling;
    dropDown.classList.toggle('tw-h-0');
  }

  function handleMenuClick(e) {
    setShowDropdown(false)
    closeMenu(true);
  }

  useEffect(() => {
    
    setShowDropdown(true)
  }, [location])
  

  let items = [];
  
  if(menuNodes !== undefined) {
     items = menuNodes.map((element, index) => {
       return <li key={index + "" + element.Id} className="hvr-underline-from-left tw-block lg:tw-inline tw-relative tw-py-5 xl:tw-p-2 tw-border-b tw-border-slate-400 lg:tw-border-none level1-item-menu" >
         <Link to={element.Url} className="level1-link tw-pl-3 tw-font-semibold md:tw-font-normal tw-text-xl md:tw-text-[1rem]" onClick={handleMenuClick}>
          {element.Name}
        </Link>
        {element.Children?.length > 0 &&
           <span className="tw-absolute tw-right-3 md:tw-right-0 lg:tw-relative lg:tw-top-[2px] tw-pl-2 " onClick={() => runFunction(itemEls.current[index])} ref={(element) => itemEls.current[index] = element} >
            {width < window.md ? <FontAwesomeIcon size="2x" icon={faAngleDown} color="primary " /> : <FontAwesomeIcon size="sm" icon={faAngleDown} color="primary " />}
          </span>}
        {(element.Children?.length > 0 && showDropdown) &&
           <ul className='tw-max-h-[60vh] tw-overflow-y-auto md:tw-bg-white lg:tw-p-0 lg:tw-shadow-md menu-drop-down menu-drop-down-level-1 lg:tw-absolute tw-pl-2 tw-h-0 tw-ease-in tw-duration-100' ref={(element) => DropMenuRefs.current[index] = element}>{element.Children.map((el, subIndex) => {
            return (
              <li key={subIndex + "" + el.Id} className="tw-pl-5 level2-item-menu hvr-underline-from-left tw-relative tw-mt-3 lg:tw-mt-1">
                <Link to={el.Url} className="level2-menu-link tw-p-2 lg:tw-p-[3px] tw-text-lg md:tw-text-base" onClick={handleMenuClick}>
                  {el.Name}
                </Link>
                {el.Children?.length > 0 &&
                  <span className="tw-ml-2 tw-mt-1 tw-absolute tw-right-[10px]" onClick={() => runFunction(itemEls.current[el.Id])} ref={(element) => itemEls.current[el.Id] = element}>
                    {width < window.md && <FontAwesomeIcon size="lg" icon={faPlus} color="primary " /> }
                    {width >= window.md && <FontAwesomeIcon size="sm" icon={faAngleDown} color="primary " />}
                  </span>}
                {el.Children.length > 0 && <ul className="tw-shadow-md lg:tw-p-0 tw-h-0 menu-drop-down-level-3 tw-overflow-hidden level-3">
                  {el.Children.map((child, ind) => {
                    return (
                      <li key={ind} className="level3-menu-item hvr-underline-from-left">
                        <a href={child.Url} className="level3-menu-link lg:tw-p-2 tw-text-lg md:tw-text-base">
                          {child.Name}
                        </a>
                      </li>
                    );
                  })
                  }
                </ul>}

              </li>
            );
          })}</ul>
        }

      </li>;
    });
  }


  return (
    <>
      {(open || width > 1023) ? <div className="NavigationMenu tw-bg-[#f7f7f7] tw-h-full">
        <ul className="tw-block  md:tw-bg-white tw-p-0 tw-m-0 lg:tw-flex lg:tw-justify-between tw-gap-12 tw-max-h-screen tw-overflow-y-auto lg:tw-overflow-visible">
          {items}
        </ul>

        {width < 1024 && 

        <>
          <CurrencySelector currecncies={currecncies} />
          {/*  {showShowGoogleTranslate && <GoogleTranslateSwitcher />} */}
          <PropertyInputSearch /> 
        </>
      }

       
      </div> : null}
    </>

  );
};
 