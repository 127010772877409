import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Skeleton from 'react-loading-skeleton';

const Map = ({ geoLocation, GoogleMapAPIKey, isLoading, distances, locationDescription }) => {
    if (!geoLocation || geoLocation.length === 0) {
        return
    }

    const [lat, lng, zoom='5'] = geoLocation.split(',').map(Number);

    if (isLoading) {
        return (
            <div>
                <h4>Map</h4>
                <Skeleton height={400} />
            </div>
        );
    }

    return (
        <>
            <LoadScript googleMapsApiKey={GoogleMapAPIKey}>
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '400px' }}
                    center={{ lat, lng }}
                    zoom={zoom}
                >
                    <Marker position={{ lat, lng }} />
                </GoogleMap>
            </LoadScript>
            {(distances && distances.length > 0) &&   
                
                <div className="tw-mt-3">
                    <h4>Distances</h4>
                    <table>
                        <thead>
                            <tr>
                                <th className="tw-border tw-border-gray-300 tw-px-4 tw-py-2">Name</th>
                                <th className="tw-border tw-border-gray-300 tw-px-4 tw-py-2">Distance</th>
                                <th className="tw-border tw-border-gray-300 tw-px-4 tw-py-2">By Car</th>
                                <th className="tw-border tw-border-gray-300 tw-px-4 tw-py-2">By Foot</th>  
                            </tr>
                        </thead>
                        <tbody>
                            {distances.map(item => (
                                <tr>
                                    <td className="tw-border tw-border-gray-300 tw-px-4 tw-py-2">{item.Name}</td>
                                    <td className="tw-border tw-border-gray-300 tw-px-4 tw-py-2">{item.Distance} {item.DistanceUnit}</td>
                                    <td className="tw-border tw-border-gray-300 tw-px-4 tw-py-2">{item.ByCar ? 'Yes' : 'No'}</td>
                                    <td className="tw-border tw-border-gray-300 tw-px-4 tw-py-2">{item.ByFoot ? 'Yes' : 'No'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
            {(locationDescription && locationDescription.length > 0) &&
                <div className="tw-mt-6">
                   <h6>Location Description</h6>
                    <p>
                        {locationDescription}
                    </p>
                </div>
            }
        </>
    );
};

export default Map;