import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {TemplateContext, PageIdContext, GetSiteDataContext } from '../../../contexts/SiteSettingsProvider';
import TemplateSkeleton from '../TemplateSkeleton/TemplateSkeleton'
import TemplateSelector from '../TemplateSelector/TemplateSelector';

function TemplateLoader() {
    const getSiteData = useContext(GetSiteDataContext);
    const template = useContext(TemplateContext);
    const pageid = useContext(PageIdContext);

    const location = useLocation();
    const [templateType, setTemplateType] = useState(null);
    const [pageId, setPageId] = useState(null);

    useEffect(() => {
        
        getSiteData();
    }, [location.pathname]);

    useEffect(() => {
            setTemplateType(template);
            setPageId(pageid);
    }, [pageid, template]);

    

    if (!templateType || !pageId || !pageId) {
        return <TemplateSkeleton />;
    }

   
 
    // Render the template based on the templateType value
    return (
        <TemplateSelector key={pageId} templateType={templateType} pageId={pageId} />
    );
}

export default TemplateLoader;