import React from 'react';
import Skeleton from 'react-loading-skeleton';

const Rates = ({ rates, isLoading }) => {
    if (isLoading) {
        return (
            <div className="reviews-container">
                <h4>Rates</h4>
                <Skeleton count={10} height={15} />
            </div>
        );
    }
 
    return (
        <div>
            <h4>Rates</h4>
            <div className="table-responsive">
                <table className="tw-w-full tw-table-auto tw-border-collapse tw-mt-2 tw-text-xs">
                    <thead>
                        <tr className="tw-bold">
                            {rates.some(rate => rate.StartDate) && <th className="tw-px-4 tw-py-2 tw-border-b tw-border-gray-300">Start Date</th>}
                            {rates.some(rate => rate.EndDate) && <th className="tw-px-4 tw-py-2 tw-border-b tw-border-gray-300">End Date</th>}
                            {rates.some(rate => rate.Nightly) && <th className="tw-px-4 tw-py-2 tw-border-b tw-border-gray-300">Nightly</th>}
                            {rates.some(rate => rate.Weekly) && <th className="tw-px-4 tw-py-2 tw-border-b tw-border-gray-300">Weekly</th>}
                            {rates.some(rate => rate.Monthly) && <th className="tw-px-4 tw-py-2 tw-border-b tw-border-gray-300">Monthly</th>}
                            {rates.some(rate => rate.MinStay) && <th className="tw-px-4 tw-py-2 tw-border-b tw-border-gray-300">MinStay</th>}
                            {rates.some(rate => rate.ArrivalDay) && <th className="tw-px-4 tw-py-2 tw-border-b tw-border-gray-300">Changeover</th>}  
                        </tr>
                    </thead>
                    <tbody>
                        {rates.map((rate, index) => (
                            <tr key={index} className={index % 2 === 1 ? '' : ''}>
                                {rate.StartDate && <td className="tw-px-4 tw-py-2 tw-border-b tw-border-gray-300" dangerouslySetInnerHTML={{ __html: rate.StartDate }}></td>}
                                {rate.EndDate && <td className="tw-px-4 tw-py-2 tw-border-b tw-border-gray-300" dangerouslySetInnerHTML={{ __html: rate.EndDate }}></td>}
                                {rate.Nightly && <td className="tw-px-4 tw-py-2 tw-border-b tw-border-gray-300" dangerouslySetInnerHTML={{ __html: rate.Nightly }}></td>}
                                {rate.Weekly && <td className="tw-px-4 tw-py-2 tw-border-b tw-border-gray-300" dangerouslySetInnerHTML={{ __html: rate.Weekly }}></td>}
                                {rate.Monthly && <td className="tw-px-4 tw-py-2 tw-border-b tw-border-gray-300" dangerouslySetInnerHTML={{ __html: rate.Monthly }}></td>}
                                {rate.MinStay && <td className="tw-px-4 tw-py-2 tw-border-b tw-border-gray-300">{rate.MinStay}</td>}
                                {rate.ArrivalDay && <td className="tw-px-4 tw-py-2 tw-border-b tw-border-gray-300" dangerouslySetInnerHTML={{ __html: rate.ArrivalDay }}></td>} 
                            </tr>
                        ))}
                        
                    </tbody>
                </table>
            </div>

            {(rates && rates.length === 0) && <div>No rates available</div>}
        </div>
    );
};

export default Rates;