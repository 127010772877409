import Skeleton from "react-loading-skeleton";
import SkeletonSearchForm from 'components/SkeletonSearchForm/SkeletonSearchForm';
import SkeletonProperty from 'components/SkeletonProperty/SkeletonProperty';
import useWindowSize from "utils/useWindowSize";
const TemplateSkeleton = () => {

    const { width } = useWindowSize();
    if (width < window.lg) {
        return (
            <>
                <div className="tw-p-3">
                    <Skeleton height={240} />
                </div>
                <div className="tw-p-3">
                    <Skeleton height={240} />
                </div>
                <div className="tw-p-3">
                    <Skeleton height={240} />
                </div>
                <div className="tw-p-3">
                    <Skeleton height={240} />
                </div>
            </>
        )
    }

    return (
        <>
 
            <div className="tw-p-3">
                <div className="tw-grid tw-grid-cols-5 tw-gap-3">
                    <div className="tw-col-span-3 lg:tw-col-span-3">
                        <div>
                            <div className="tw-flex tw-justify-end tw-flex lg:tw-justify-between tw-bg-slate-100 tw-p-2 tw-content-center tw-mb-4 tw-h-[50px]"></div>
                            <div className="tw-grid sm:tw-grid-cols-1 tw-grid-cols-1">
                                <SkeletonProperty />
                                <SkeletonProperty />
                                <SkeletonProperty />
                                <SkeletonProperty />
                                <SkeletonProperty />
                                <SkeletonProperty />
                            </div>
                        </div>

                    </div>
                    <div className="lg:tw-columns-1 tw-col-span-2 ">
                        <div className="tw-h-[100vh] tw-bg-[#eee]">

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default TemplateSkeleton;
