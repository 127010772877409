import React, { useRef, useState, useEffect }from 'react';
import PropTypes from 'prop-types';
import styles from './MapSwitcher.module.css';
import './MapSwitcher.css';
import Form from 'react-bootstrap/Form';
import useWindowSize from "utils/useWindowSize";

 
const MapSwitcher = ({ text, shown, upMapState }) => {

  const inputRef = useRef()

  const { width } = useWindowSize();
  const { height } = useWindowSize();

  const [mapIsShown, setMapState] = useState(shown);
  const [switcherText, setSwitcherText] = useState(text);

  const changeMapState = (e) => {
    setMapState(!mapIsShown);
    upMapState(mapIsShown);
  }

 

  const handleMapStateOnMobileOnClick = (e) => {
    var mobileForm = document.getElementById("mobileForm");
    var navigation = document.querySelector(".Navigation");
    if (mobileForm) {
      window.scrollTo({ top: mobileForm.offsetTop, behavior: 'smooth' });
    } else if (navigation) {
      window.scrollTo({ top: navigation.offsetTop, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    setMapState(!mapIsShown);
    upMapState(mapIsShown);
  }





  useEffect(() => {
    let body = document.getElementsByTagName("body");
    if (mapIsShown){
      setSwitcherText("Hide Map");
    }else{
      setSwitcherText("Show Map");
    }

    if (mapIsShown && width < window.lg){
      body[0].style.overflow = "hidden";

    }else{
      body[0].style.overflow = "visible";
    }

    
 
  }, [mapIsShown])

  useEffect(() => {
   
    if (width < window.lg  ) {
      setSwitcherText("Show Map");
      setMapState(false);
    }else{
      setSwitcherText("Hide Map");
      setMapState(true);
    }
  }, [width])

  useEffect(() => {
    if (width < window.lg) {
      setSwitcherText("Show Map");
      setMapState(false);
    }
  }, [])



  return(
    <>
      {(width > window.lg) ?
        <div className={styles.MapSwitcher + " tw-flex tw-tw-content-center tw-items-center form-switch-lg"}>
          <Form.Check
            reverse
            size="lg"
            label={switcherText}
            type="switch"
            id="cusom-switch"
            className="tw-flex tw-tw-content-center form-switch-lg"
            onClick={changeMapState}
            defaultChecked={shown}
          />
        </div> : 
        <button type="button" className="btn btn-dark tw-fixed tw-bottom-3 tw-rounded-xl tw-left-[50%] tw-ml-[-40px] show-map-mobile" onClick={handleMapStateOnMobileOnClick}>{switcherText}</button>
     
      }
    </>

  )
};

MapSwitcher.propTypes = {};

MapSwitcher.defaultProps = {};

export default MapSwitcher;
