import React, { useState, useEffect } from 'react';
import styles from './WishButton.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { getcookie, setCookie } from 'utils/cookieUtils'; // assumes cookie utilities have been moved to a separate file

const WishButton = ({ id, heartColor, size, className, showText = false }) => {
  const [inWishList, setWishState] = useState(false);
  const [isMessageShown, setMessageState] = useState(false);
  const [messageText, setMessageText] = useState("");

  useEffect(() => {
    const currentCookies = getcookie('ipro-wishlist');
    if (currentCookies) {
      const wishCookiesArr = currentCookies.split(",");
      setWishState(wishCookiesArr.includes(id.toString()));
    }
  }, [id]);

  useEffect(() => {
    if (isMessageShown) {
      const timerId = setTimeout(() => setMessageState(false), 2000);
      return () => clearTimeout(timerId);
    }
  }, [isMessageShown]);

  const changeWishState = () => {
    setWishState(!inWishList);

    let currentCookies = getcookie('ipro-wishlist');
    if (!currentCookies) {
      setCookie("ipro-wishlist", id);
      setMessageText(`<a href='/contact'>Click here to submit an enquiry</a>`);
      setMessageState(true);
      return;
    }

    let wishCookiesArr = currentCookies.split(",");
    if (wishCookiesArr.includes(id.toString())) {
      wishCookiesArr = wishCookiesArr.filter(item => item !== id.toString());
      setCookie("ipro-wishlist", wishCookiesArr.join());
      setMessageText("This property was removed from my favourites");
    } else {
      wishCookiesArr.push(id);
      setCookie("ipro-wishlist", wishCookiesArr.join());
      setMessageText(`<a href='/contact'>Click here to submit an enquiry</a>`);
    }
    setMessageState(true);
  }

  return (
    <div className={`${styles.WishButton} ${className}`} onClick={changeWishState}>
      {isMessageShown &&
        <div className="alert alert-success !tw-absolute !tw-top-20 !tw-left-3 tw-right-3 tw-z-10 tw-text-center " role="alert">
          <span dangerouslySetInnerHTML={{ __html: messageText }}></span>
          {/* <button type="button" className="btn-close tw-absolute tw-right-2 tw-top-2" data-bs-dismiss="alert" aria-label="Close" onClick={() => setMessageState(false)}></button> */}
        </div>
      }
      <FontAwesomeIcon
        icon={inWishList ? fasHeart : farHeart}
        color={heartColor}
        size={size}
        //className={className}
        
      />
      {showText && <span className="tw-ml-2 tw-font-bold">{inWishList ? "Remove from my favourites" : "Add to my favourites"}</span>}  
    </div>
  )
};

export default WishButton;