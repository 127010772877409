import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

const Description = ({ intro, description, cons, pros, isLoading }) => {
    const [isDescriptionShown, setDescriptionShown] = useState(false);

    // React to changes in `intro` and `description` props.
    useEffect(() => {
        // If 'intro' is empty and 'description' is not, show 'description' by default.
        if (!intro && description) {
            setDescriptionShown(true);
        }
    }, [intro, description]);

    const toggleDescription = () => {
        setDescriptionShown(!isDescriptionShown);
    };

    if (isLoading){
        return(
            <div>
                <h4>Description</h4>
                <Skeleton count={2} height={20} /> 
            </div>
        );
    }

    return (
        <div >
            <h4>Description</h4>
            {intro && 
                <p>
                    {intro}
                    
                    
                </p>}
            <img src="/media/43658574/Italy-Villa-Specialists-Holiday-Homes-In-Italy-1.jpg" className="img-responsive tw-mt-3" />
            {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
   


            {((cons && cons.length > 0) || (pros && pros.length > 0)) && (
                <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-bg-white tw-border-l-4 tw-border-slate-200">
                    {cons && cons.length > 0 && (
                        <div>
                            <h2 className="tw-text-lg tw-font-semibold tw-mb-0">Highlights of the Property</h2>
                            <p className="tw-text-base">{cons}</p>
                        </div>
                    )}

                    {pros && pros.length > 0 && (
                        <div>
                            <h2 className="tw-text-lg tw-font-semibold tw-mb-0">What Guests Should Know?</h2>
                            <p className="tw-text-base">{pros}</p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Description;