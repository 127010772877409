import React, { useEffect } from 'react';

const JsContent = ({ content }) => {
    const contentRef = React.createRef();

    useEffect(() => {
        const el = contentRef.current;
        if (!el) return;

        el.innerHTML = content;  // Fix: Changed from 'el.innercontent' to 'el.innerHTML'

        const scripts = el.getElementsByTagName('script');
        for (let script of scripts) {
            const newScript = document.createElement('script');
            newScript.src = script.src;
            newScript.async = script.async;
            newScript.defer = script.defer;
            newScript.innerHTML = script.innerHTML; // New: Copy inner HTML

            // Copy all attributes
            for (let attr of script.attributes) {
                newScript.setAttribute(attr.name, attr.value);
            }

            script.parentNode.replaceChild(newScript, script);
        }
    }, [content]);

    return <div ref={contentRef} />;
};

export default JsContent;
 


