import React, { useState, useContext, useEffect, useRef } from 'react';
import styles from './SearchFormMobile.module.css';
import LocationMobile from '../../../../SearchForm/Location/LocationMobile';
import GuestsMobile from '../../../../SearchForm/Guests/GuestsMobile';
import DatePickerMobile from '../../../../SearchForm/DatePicker/DatePickerMobile';
import FiltersMobile from '../../../../SearchForm/Filters/FiltersMobile';
import { PageIdContext } from '../../../../../contexts/SiteSettingsProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import useWindowSize from "utils/useWindowSize";

const SearchFormMobile = ({ 
    view = "horizontal",
    searchFormSettings,
    locations,
    settings,
    attributes,
    enableLocationFilter,
    enableDateFilter,
    enableGuestFilter,
    enablePriceRangeSlider,
    enableFlexibleNightsFilter,
    enableAttributesFilter,
    enableSearchForm,
    isFilters,
    priceRangeSliderSettings,
    priceRange,
    startMobileSearch,
    clearAllData,
    transferLocation,
    transferDates,
    transferFlexibility,
    transferPriceMode,
    transferAttrsOnLoad,
    transferAttrsOnChange,
    transferAdults,
    transferChildren,
    transferInfants,
    startSearch,
    transNightRange,
    transWeekRange,
    propertyAttributesIds,
    locationsIds,
    changedNumberOfAdults,
    changedNumberOfChildren,
    changedNumberOfInfants,
    changedLocation,
    changedDates
 }) => {

    const inputRef = useRef()
    const [openSection, setOpenSection] = useState(1);
    const [showMobileForm, setShowMobileForm] = useState(false);
    const pageID = useContext(PageIdContext);
    const [clearChildFiled, setClearChildFiled] = useState(0);
    const [isFixed, setIsFixed] = useState(false);
    const { width } = useWindowSize();
   
    useEffect(() => {
        const stickyDiv = document.getElementById('mobileForm');
        if (stickyDiv) {
            const stickyDivTop = stickyDiv.getBoundingClientRect().top + window.scrollY;

            const handleScroll = () => {
                if (window.scrollY >= stickyDivTop) {
                    setIsFixed(true);
                } else {
                    setIsFixed(false);
                }
            };

            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, []);



    function toggleMobileSearchForm(isActive = null) {
 
        let currentState = document.body.classList.contains('mobile-search-active');

        // If isActive is provided, use it. Otherwise, toggle based on the current state.
        let newState = (isActive !== null) ? isActive : !currentState;

        if (newState) {
            document.body.classList.add('mobile-search-active');
        } else {
            document.body.classList.remove('mobile-search-active');
        }

        setShowMobileForm(!showMobileForm);
    }


    const handleAccordionChange = (currentSection) => {
        if (currentSection === 1) {
            setOpenSection(openSection === 1 ? null : 1);
        } else if (currentSection === 2) {
            setOpenSection(openSection === 2 ? 3 : 2);
        } else if (currentSection === 3) {
            setOpenSection(openSection === 3 ? 2 : 3);
        }
    };

    function startSearch(e) {
         
        if (showMobileForm && width > window.lg) {
            startMobileSearch();
            window.scrollTo(0, 0)
        }

        var panel = document.getElementById("SortPanel");
        if (showMobileForm && width < window.lg) {
            if (panel) {
                var SortPanel = panel.offsetTop;
                window.scrollTo({ top: SortPanel, behavior: 'smooth' });
            }
            startMobileSearch();
        }

        setShowMobileForm(!showMobileForm);
        toggleMobileSearchForm(false);
    }


    const isHomePage = window.location.pathname === '/' || window.location.pathname === '/home';

 

    return (
        <>
            
            {
                <div
                    id="mobileForm"
                    style={{
                        position: isFixed ? 'fixed' : 'sticky',
                        top: '0',
                        width: isFixed ? '100%' : 'auto',  // Make width 100% when fixed
                        left: isFixed ? '0' : 'auto',  // Set left to 0 when fixed
                    }}
                    className="tw-sticky tw-top-0 tw-z-[9] mobile-search-start-button   tw-mb-3 tw-drop-shadow-lg tw-left-10 tw-right-10 tw-bg-primary"
                    ref={inputRef}
                    onScroll={() => {
                        const { offsetTop } = inputRef.current;
                    }}
                >
                    <div className="tw-p-3">
                        <div className="form-control form-control-lg tw-relative tw-z-70" onClick={toggleMobileSearchForm}>
                            <span>{isHomePage ? 'Start Your Search' : 'Refine my search results'}</span>
                            <svg
                                className="svgFilters"
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                role="presentation"
                                focusable="false"
                            >
                                <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            }



            {showMobileForm && 
                <div className={styles.SearchFormMobile}  >

                    <div className={'mobileForm tw-top-0 tw-left-0 tw-fixed tw-bg-white tw-w-full tw-h-full tw-z-[999] tw-p-3'}>
                        <div className="tw-absolute tw-left-0 tw-top-0 tw-w-full shadow-sm tw-p-3 mobile-search-form-header">
                            <>
                                <button type="button" className="btn-close" onClick={() => toggleMobileSearchForm(false)} aria-label="Close"></button>
                            </>
                        </div>

                        <div className="tw-mt-[50px] tw-mb-[50px]">
                            <div className={view + "  tw-relative "}>
                                <div className="serch-form-field tw-bg-white location" >
                                    <LocationMobile
                                        onToggle={() => handleAccordionChange(1)}
                                        isOpen={openSection === 1}
                                        pageID={pageID}
                                        clearField={clearChildFiled}
                                        locationList={searchFormSettings.Locations}
                                        changeLocation={transferLocation}
                                        enableLocationFilter={searchFormSettings.EnableLocationFilter}
                                        settings={searchFormSettings}
                                        locationsIds={locationsIds}
                                        changedLocation={changedLocation}
                                    />
                                </div>
                                <div className="serch-form-field tw-bg-white dates tw-relative tw-z-20">
                                    <DatePickerMobile
                                        isOpen={openSection === 2}
                                        onToggle={() => handleAccordionChange(2)}
                                        clearField={clearChildFiled}
                                        changeDates={transferDates}
                                        enableDateFilter={searchFormSettings.EnableDateFilter}
                                        onChangeFlexibility={transferFlexibility}
                                        enableFlexibleNightsFilter={searchFormSettings.enableFlexibleNightsFilter}
                                        changedDates={changedDates}
                                      
                                    />
                                </div>

                                {searchFormSettings.EnableGuestFilter &&
                                    <div className="serch-form-field tw-bg-white guests tw-relative tw-z-10">
                                        <GuestsMobile
                                            isOpen={openSection === 3}
                                            onToggle={() => handleAccordionChange(3)}
                                            clearField={clearChildFiled}
                                            changeAdults={transferAdults}
                                            changeChildren={transferChildren}
                                            changeInfants={transferInfants}
                                            enableGuestFilter={searchFormSettings.EnableGuestFilter}
                                            changedNumberOfAdults={changedNumberOfAdults}
                                            changedNumberOfChildren={changedNumberOfChildren}
                                            changedNumberOfInfants={changedNumberOfInfants}
                                        />
                                    </div>
                                }

                                <div className="serch-form-field tw-bg-white filters">
                                    <FiltersMobile
                                        changePriceMode={transferPriceMode}
                                        changeAttrsOnLoad={transferAttrsOnLoad}
                                        changeAttrsOnValueChange={transferAttrsOnChange}
                                        startSearchFromModal={startSearch}
                                        attributesList={attributes}
                                        enabledFilters={isFilters}
                                        enablePriceRangeSlider={enablePriceRangeSlider}
                                        priceRangeSliderSettings={priceRangeSliderSettings}
                                        changeNightRange={transNightRange}
                                        changeWeekRange={transWeekRange}
                                        clearField={clearChildFiled}
                                        propertyAttributesIds={propertyAttributesIds}
                                    />
                                </div>

                            </div>
                        </div>

                        <div className="tw-absolute tw-left-0 tw-bottom-0 tw-w-full shadow tw-p-3 mobile-search-form-footer tw-flex tw-justify-between">
                            <button type="button" className="btn btn-link tw-text-black" onClick={clearAllData}>Clear All</button>
                            <button
                                type="button"
                                className="btn btn-primary text-white"
                                onClick={() => {
                                    startSearch();
                                    toggleMobileSearchForm(false);
                                }}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            }

        </>
    );
};

export default SearchFormMobile;
