import { useEffect, useState, useContext } from 'react';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import useWindowSize from "utils/useWindowSize";
import 'swiper/css';
import 'swiper/css/navigation';
import './LocationPickerWidget2.scss';

// install Swiper's Navigation module
SwiperCore.use([Navigation]);

const LocationPickerWidget2 = ({ data }) => {
    const { width } = useWindowSize();
    const { Locations } = data;
    const maxColumns = 4;  // Maximum number of columns you want to have.
    const numberOfLocations = Locations.length;
    let remainingLocations = numberOfLocations;
    let [slidesPerView, setSlidesPerView] = useState(4);
    let rows = [];

    // Calculate rows
    while (remainingLocations > 0) {
        const itemsInRow = remainingLocations >= maxColumns ? maxColumns : remainingLocations;
        rows.push(itemsInRow);
        remainingLocations -= itemsInRow;
    }

    useEffect(() => {
        if (width < window.lg) {
            setSlidesPerView(2);
        }
        if (width < window.md) {
            setSlidesPerView(1);
        }
        if (width >= window.lg) {
            setSlidesPerView(5);
        }
    }, [width]);

    return (
        <WidgetWrapper data={data}>
            <div className="tw-bg-white  tw-rounded-md overflow-visible locations-gallery">
                <h2 className="tw-text-2xl tw-mb-3">{data.LocationsTitle}</h2>
                <div className="tw-mb-5" dangerouslySetInnerHTML={{ __html: data.LocationsDescription }} />
                <Swiper spaceBetween={20} slidesPerView={slidesPerView} navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}  >
                    {Locations.map((location, index) => (
                        <SwiperSlide key={index}>
                            <div className="image-container tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-gray-50 tw-rounded tw-shadow-md tw-text-center overflow-visible">
                                <a href={location.LocationUrl} className="tw-no-underline">
                                    <div className="image-wrapper tw-relative tw-overflow-hidden tw-transition-all tw-duration-300"> {/*This div now wraps the image only*/}
                                        <img src={location.LocationImages.Url} alt={location.LocationImages.Name} className=" scale-up " />
                                        <div className="tw-inset-0 tw-bg-black"></div>
                                    </div>
                                    <div className="tw-z-50 tw-w-full tw-top-0 tw-h-full tw-absolute tw-flex tw-flex-col tw-items-center tw-justify-center">
                                        <h2 className="tw-text-2lg tw-mt-2 tw-mb-0 tw-text-white">{location.LocationName}</h2>
                                        <span className="tw-no-underline tw-mt-3 tw-px-4 tw-py-1 tw-border tw-border-white  tw-text-white hover:tw-text-black hover:tw-bg-white tw-transition-all tw-duration-300">View</span>
                                    </div>
                                </a>

                            </div>
                        </SwiperSlide>
                    ))}
                    <div className="swiper-button-next round"></div>
                    <div className="swiper-button-prev round"></div>
                </Swiper>
            </div>
        </WidgetWrapper>
    );
};

export default LocationPickerWidget2;