import { useEffect, useState, useContext } from 'react';
import PropertyItem from 'components/SearchResult/PropertyItem/PropertyItem';
import SkeletonProperty from 'components/SkeletonProperty/SkeletonProperty';
import ReactPaginate from 'react-paginate';
const PropertiesList = ({ propertyList, displayMode, isFetching, sendParams, total, dictionary }) => {

    const [gridClasses, setGridClasses] = useState('tw-grid sm:tw-grid-cols-2  xl:tw-grid-cols-3 tw-gap-4');
    const [loading, setLoading] = useState(true);
    const [messageText, setMessageText] = useState('test');
    
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        if (displayMode) {
            setGridClasses("tw-grid sm:tw-grid-cols-1 tw-grid-cols-1")
        }else{
            setGridClasses("tw-grid sm:tw-grid-cols-1  md:tw-grid-cols-2 2xl:tw-grid-cols-2 tw-gap-4")
        }
        if (displayMode === 'fullWidth') {
            setGridClasses("tw-grid sm:tw-grid-cols-2  xl:tw-grid-cols-4 tw-gap-4")
        }
    }, [displayMode]);


    useEffect(() => {
        setPageCount(Math.ceil(total / 20));
    }, [total]);

    function handlePageClick(e) {
        var panel = document.getElementById("SortPanel");

        if (panel) {
            var SortPanel = panel.offsetTop;
            window.scrollTo({ top: SortPanel, behavior: 'smooth' });
        }

 
        sendParams({Show: "20", Page: e.selected + 1, clearcache: false });
        
    }

 
    

    if (!propertyList) {
        return "No Properties"
    }

    const listItems = propertyList.map((item, index) => {
  
        return (
            <>
                {isFetching ? <SkeletonProperty count={20} /> : <PropertyItem key={item.Id} item={item} index={index} enableSlider={true} dictionary={dictionary} />}
            </>
        );
    }
    );


    return (
        <div className={""}>
            <div className={gridClasses}>
                {listItems}
            </div>
            {total > 20 ? <ReactPaginate
                className='pagination tw-mt-5'
                breakLabel="..."
                previousLabel="<"
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={3}
                pageCount={pageCount}
                pageClassName='page-item'
                pageLinkClassName="page-link"
                previousClassName="page-item "
                nextClassName="page-item"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"

                activeClassName="active tw-cursor-default"
                disabledClassName='disabled'
                breakClassName="page-item"
                breakLinkClassName='page-link'
            /> : ""}
        </div>


    );
}


export default PropertiesList;