import { useEffect, useState, useContext } from 'react';
import SkeletonLocation from './SkeletonLocation/SkeletonLocation';
import Slider from './Slider/Slider';
import SearchForm from '../../components/SearchForm/SearchForm';
import { SiteSettingsContext, CurrencyContext, SEOContext, SortContext } from '../../contexts/SiteSettingsProvider';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import useWindowSize from "utils/useWindowSize";
import SearchControlPanel from 'components/SearchResult/SearchControlPanel/SearchControlPanel';
import SearchResultMap from 'components/SearchResultMap/SearchResultMap';
import PropertiesList from 'pages/Location/Properties/PropertiesList.js';
 

const Location = ({ pageId, keyID }) => {
 
  const {updateSeo } = useContext(SEOContext);
  const { sort: SortParam, order: OrderParam } = useContext(SortContext);

  const [isFetching, setFetching] = useState(false);
  const siteSettings = useContext(SiteSettingsContext);
  const { width } = useWindowSize();
  let [sliderInfo, setSliderInfo] = useState(null);
  const [currency, setCurrency] = useContext(CurrencyContext);
  const [mapIsShwon, setMapVisability] = useState(true);
 
  const [propertyList, setPropertyList] = useState(null);
  const [googleMapAPIKey, setGoogleMapAPIKey] = useState('');
  const [propertyGridClasses, setPropertyGridClasses] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [sort, setSort] = useState('');
  const [total, setTotal] = useState('');
  const [dictionary, setDictionary] = useState(null);
  const [locationSummary, setLocationSummary] = useState('');
  const [introText, setIntroText] = useState('');
  const [displayMode, setDisplayMode] = useState("map");
  const [showIntroText, setShowIntroText] = useState(false);
  const [widgets, setWidgets] = useState([]);
  const [settings, setSettings] = useState(null);
  // Check if locationSummary is empty and show introText instead
  const textToDisplay = locationSummary ? locationSummary : introText;

  // Show read more button only if locationSummary is not empty
  const showReadMoreButton = locationSummary && introText;

  useEffect(() => {
    setGoogleMapAPIKey(siteSettings.SiteSettings.GoogleMapAPIKey);
    if (width < window.lg) {
      setMapVisability(false);
    }
  }, []);

  useEffect(() => {
    if (width < window.lg) {
      setMapVisability(false);
    } else {
      setMapVisability(true);
    }
  }, [width]);


  useEffect(() => {
    if (mapIsShwon === false) {
      setDisplayMode('fullWidth');
    }
    if (mapIsShwon === true) {
      setDisplayMode('map');
    }
  }, [mapIsShwon, displayMode]);

  useEffect(() => {
    
    if (mapIsShwon){
      setPropertyGridClasses('tw-col-span-3 order-lg-1');
    } 
  }, [mapIsShwon]);
  

  const getContent = () => {
    setFetching(true);
    if (pageId) {
      fetch(`${process.env.REACT_APP_BASE_URL}apis/locationpage?pageid=${pageId}&clearcache=true`)
        .then(response => response.json())
        .then(data => {
         

          setIntroText(data.Location.IntroText);
          setLocationSummary(data.Location.LocationSummary);
          setDictionary(data.Settings.Dictionaries);
          setSliderInfo(data);
          setFetching(false);
          setWidgets(data.Location.Widgets);
          setSettings(data.Settings.SearchFormSettings);
          updateSeo(data.Settings.Seo);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }

  const getProperties = (sortDirection = null, Sort = null, Show, Page, clear) => {
    if (!clear){
      clear = true;
    }
    
    setFetching(true);
    if (pageId) {
      fetch(`${process.env.REACT_APP_BASE_URL}apis/searchproperties?pageid=${pageId}&location=${pageId}&clearcache=${clear}&SortDirection=${sortDirection || OrderParam}&Sort=${Sort || SortParam}&Currency=${currency}&Show=${Show}&Page=${Page}`)
        .then(response => response.json())
        .then(data => {
          setPropertyList(data.Items);
          setTotal(data.Total);
          setFetching(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }

  useEffect(() => {
    getContent();
    getProperties();
  }, [pageId, currency])

  if (!sliderInfo) {
    return (
      <SkeletonLocation />
    );
  }

  function upMapState(val) {
    setMapVisability(!val);
  }

  function changeSorting(sort,order) {
    setSortDirection(order);
    setSort(sort);
    getProperties(order, sort);
 
  }

  const updateResults = (e) => {
    getProperties(sortDirection, sort, e.Show, e.Page, e.clearcache );
  }
 
  if (!sliderInfo){
    return  
  }

 
  if (!sliderInfo && !propertyList) {
    window.location = "/search/";
  }

 
 
  return (
    <>
 


      {(sliderInfo && sliderInfo.Location.Title && sliderInfo?.Location?.Images)  && 
        <Slider url={sliderInfo?.Location?.Images[0]?.Url} title={sliderInfo?.Location?.Title} subtitle={sliderInfo?.Location?.SubTitle} >
          <div className="md:tw-px-3 lg:tw-bg-[#fff] tw-mt-10  md:tw-w-auto">
              <SearchForm pageID={pageId} keyID={keyID} Currency={siteSettings.Currency} settings={settings} />
          </div>
        </Slider>
      }
      

      {(introText && textToDisplay) &&  
        <div className="tw-p-10 tw-shadow-xl tw-m-3 tw-border tw-border-slate-100  ">
          <p dangerouslySetInnerHTML={{ __html: textToDisplay }}></p>
          {showIntroText && (
            <p dangerouslySetInnerHTML={{ __html: introText }}></p>
          )}
          {showReadMoreButton && (
            <button onClick={() => setShowIntroText(!showIntroText)} className="btn btn-primary btn-sm">
              {showIntroText ? 'Read less' : 'Read more'}
            </button>
          )}
        </div>
      }



      <div className="tw-p-3">

        {propertyList ? <div>
          <div className='tw-grid  tw-grid-cols-1 lg:tw-grid-cols-5 tw-gap-3'>
            {mapIsShwon ? <div className="tw-col-span-3 lg:tw-col-span-3">
              <SearchControlPanel upMapState={upMapState} updateSorting={changeSorting} />
              <PropertiesList propertyList={propertyList} displayMode={mapIsShwon} isFetching={isFetching} dictionary={dictionary} total={total} sendParams={updateResults} />
            </div> :
              <div className="tw-col-span-5 order-lg-2">
                <SearchControlPanel upMapState={upMapState} updateSorting={changeSorting} />
                {propertyList && <PropertiesList propertyList={propertyList} displayMode={mapIsShwon} dictionary={dictionary} total={total} sendParams={updateResults} />}
              </div>
            }
            {
              (mapIsShwon && width > window.lg) ? <div className="lg:tw-columns-1 tw-col-span-2 ">
                <div className="tw-sticky tw-top-0">
                  <div className="">
                    {propertyList &&
                      <Wrapper apiKey={googleMapAPIKey}  >
                        <SearchResultMap mapData={propertyList} />
                      </Wrapper>
                    }
                  </div>
                </div>
              </div> : null
            }
            {(mapIsShwon && width < window.lg) ?
              <div className="tw-fixed tw-w-full tw-h-full tw-z-[9] tw-top-0 tw-left-0">
                <div className="map">
                  <div className="">
                    {propertyList &&
                      <Wrapper apiKey={googleMapAPIKey}  >
                        <SearchResultMap mapData={propertyList} />
                      </Wrapper>
                    }
                  </div>
                </div>
              </div> : null
            }
          </div>
        </div> : ''}

      </div>

      
    </>
  );
};



export default Location;
